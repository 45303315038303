import React from 'react';
import Select from 'react-select';
import axios from 'axios';
import moment from 'moment';
import Toggle from 'react-toggle';

import 'react-toggle/style.css'

import de from './locales/de.json'
import en from './locales/en.json'
import jp from './locales/jp.json'

export const BEARER_TOKEN = window.getCookie('token') ? window.getCookie('token') : process.env.REACT_APP_JWT_TOKEN ? process.env.REACT_APP_JWT_TOKEN : '123'

const timeFormat = document.querySelector('body').getAttribute('data-locale') === 'de-DE' ? 'HH.mm [Uhr]' : 'h:mma'

let API_PREFIX = ``

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  API_PREFIX = `https://www.daran-gedacht.de`
}

let DISABLE_LINKS = false

const LANGUAGE = document.querySelector('body').getAttribute('data-locale') === 'jp-JP' ? jp : document.querySelector('body').getAttribute('data-locale') === 'de-DE' ? de : en
const TOOLTIP_TEXT = LANGUAGE.BOOKMARK_SESSION_TOOLTIP

// IMPORTANT NOTE: All times are currently passed from the DB as UTC --> BUT <-- the editor has entered them as CEST which means we must offset the +2 hours difference).
let timezoneOffsetFromUTC = new Date().getTimezoneOffset() + (parseFloat(document.querySelector('body').getAttribute('data-timezone-minutes'))) // we subtract 120 because of the mins (2 hour) offset requirement (see note above)

if (timezoneOffsetFromUTC < 0) {
  timezoneOffsetFromUTC = Math.abs(timezoneOffsetFromUTC)
} else {
  timezoneOffsetFromUTC = -Math.abs(timezoneOffsetFromUTC)
}

class App extends React.Component {
  constructor(props) {
    super(props)

    let timeHourOptions   = []
    let selectHours = []
    let selectMinutes = []

    for (var i = 0; i < 24; i++) {
      timeHourOptions.push(<option>{ i < 10 ? `0${i}` : i }</option>)
      selectHours.push({
        value: i < 10 ? `0${i}` : i,
        label: i < 10 ? `0${i}` : i
      })
    }

    for (i = 0; i < 60; i = i + 5) {
      selectMinutes.push({
        value: i < 10 ? `0${i}` : i,
        label: i < 10 ? `0${i}` : i
      })
    }

    this.state = {
      isLoading: true,
      showForm: false,
      currentDate: '',
      rawDays: [],
      events: [],
      preppedEvents: [],
      starredEvents: [],
      filters: [],
      selectDays: [],
      selectHours,
      selectMinutes,
    }
  }
  componentDidMount() {
    this.getData()
  }
  getEvents() {
    return new Promise((resolve, reject) => {
      axios.get(
        `${API_PREFIX}/wp-json/calendar/v1/get?token=${BEARER_TOKEN}`,
        {
          headers: {
            'Authorization': `Bearer ${BEARER_TOKEN}`,
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      ).then(response => {
        console.log('calendar response', response, window.location.href)
        if(window.location.href.includes("https://www.daran-gedacht.de")){
          DISABLE_LINKS = true
          console.log('calendar response DISABLE_LINKS')
        }
        if (typeof response.data !== 'undefined') {
          resolve(response.data)
        }
        reject()
      }).catch(() => {
        reject()
      })
    })
  }
  prepare(days) {
    return new Promise((resolve, reject) => {
      let events = []

      days.forEach((day) => {
        day.entries.forEach((event) => {
          event.isVisible = true // show all by default
          events.push(event)
        })
      })

      resolve(events)
    })
  }
  getData() {
    this.getEvents().then((events) => {

      let role = events.role

      let eventDays = []
      let selectDays = []

      if (typeof events.event_days !== 'undefined') {
        Object.keys(events.event_days).forEach((day) => {
          eventDays.push(events.event_days[day])
        })
        eventDays.forEach((day) => {
          selectDays.push({ value: day, label: day })
        })
      }

      let rawDays = []
      if (typeof events.days !== 'undefined') {
        rawDays = events.days
      }

      let starredEvents = []

      if (typeof events.starred_events !== 'undefined' && events.starred_event != null && events.starred_events?.length > 0) {
        events.starred_events.forEach((event) => {
          starredEvents[event] = true
        })
      }

      let categories = []
      let selectCategories = []
      let topics = []
      let selectTopics = []
      let markets = []
      let selectMarkets = []
      let channels = []
      let selectChannels = []
      let representatives = []
      let selectRepresentatives = []

      if (typeof events.categories !== 'undefined' && events.categories) {
        Object.keys(events.categories).forEach((category) => {
          categories.push(category)
        })
        selectCategories.push({ value: null, label: 'All Regions' })
        categories.forEach((category) => {
          selectCategories.push({ value: category, label: category })
        })
      }

      if (typeof events.topics !== 'undefined' && events.topics) {
        Object.keys(events.topics).forEach((topic) => {
          topics.push(topic)
        })
        selectTopics.push({ value: null, label: 'All Categories' })
        topics.forEach((category) => {
          selectTopics.push({ value: category, label: category })
        })
      }

      if (typeof events.markets !== 'undefined' && events.markets) {
        Object.keys(events.markets).forEach((market) => {
          markets.push(market)
        })
        selectMarkets.push({ value: null, label: 'All Markets' })
        markets.forEach((market) => {
          selectMarkets.push({ value: market, label: market })
        })
      }

      if (typeof events.channels !== 'undefined' && events.channels) {
        Object.keys(events.channels).forEach((channel) => {
          channels.push(channel)
        })
        selectChannels.push({ value: null, label: 'All Channels' })
        channels.forEach((category) => {
          selectChannels.push({ value: category, label: category })
        })
      }

      if (typeof events.representatives !== 'undefined' && events.representatives) {
        Object.keys(events.representatives).forEach((representative) => {
          representatives.push(events.representatives[representative])
        })
        selectRepresentatives.push({ value: null, label: 'All Speakers' })
        representatives.forEach((rep) => {
          selectRepresentatives.push({ value: rep.ID, label: `${rep.first_name} ${rep.last_name}` })
        })
      }

      this.prepare(rawDays).then((events) => {

        // Reset any loading states
        let entries = document.querySelectorAll('.entry.loading')
        entries.forEach((element) => {
          element.classList.remove('loading')
        })

        let preppedEvents = [],
            currentDate = null

        events.forEach((event, index) => {
          if (currentDate !== moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY')) {
            preppedEvents.push(<EntryDate timestamp={ moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('X') } date={ moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY') } />)
            currentDate = moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY')
          }

          preppedEvents.push(<Entry isVisible={ event.isVisible } starredEvents={ starredEvents } setStarredEvents={ (events) => this.setStarredEvents(events) } id={ event.ID } isBookmarked={ typeof starredEvents[event.ID] !== 'undefined' && starredEvents[event.ID] ? true : false } date={ moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY') } meta={ `${moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format(timeFormat)} - ${moment(`${event.end.year}-${event.end.month}-${event.end.day}T${event.endHour}:${event.endMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format(timeFormat)}` } category={ event.categories && event.categories.length > 0 ? event.categories[0] : null } title={ event.title } copy={ event.copy } link={ event.link } speaker={ event.representatives && event.representatives.length > 0 ? `${event.representatives[0].first_name} ${event.representatives[0].last_name}` : null } channel={ event.channels && event.channels.length > 0 ? event.channels[0] : null } />)
        })

        this.setState({
          isLoading: false,
          currentDate: events && events[0] ? moment(`${events[0].start.year}-${events[0].start.month}-${events[0].start.day}T00:00:00.000Z`).format('ddd, DD. MMM YYYY') : null,
          eventDays,
          selectDays,
          events,
          preppedEvents,
          starredEvents,
          categories,
          selectCategories,
          topics,
          selectTopics,
          markets,
          selectMarkets,
          channels,
          selectChannels,
          representatives,
          selectRepresentatives,
          role
        }, () => {

          axios.get(
            `${API_PREFIX}/wp-json/directory/v1/list?token=${BEARER_TOKEN}`,
            {
              headers: {
                'Authorization': `Bearer ${BEARER_TOKEN}`,
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          ).then(response => {
            console.log('participant list data', response.data)
            if (typeof response.data !== 'undefined' && typeof response.data.directory !== 'undefined') {

              let selectParticipants = []
              let participants = response.data.directory
              console.log('participants', participants)

              participants.forEach((participant) => {
                console.log('participant', participant)
                if (participant.company) {
                  selectParticipants.push({ value: participant.id, label: `${participant.first_name} ${participant.last_name} (${participant.company})` })
                } else {
                  selectParticipants.push({ value: participant.id, label: `${participant.first_name} ${participant.last_name}` })
                }
              })

              this.setState({
                participants,
                selectParticipants
              })
            } else {
              alert('Participants could not be retrieved.')
            }
          })
        })
      })
    })
  }
  setStarredEvents(starredEvents) {

    let that = this
    var form_data = new FormData();

    starredEvents.forEach((event, index) => {
      if (event) {
        form_data.append(`event[${index}]`, true);
      }
    })

    // Show the recaptcha badge for transparency.
    let badges = document.querySelectorAll('.grecaptcha-badge')
    badges.forEach((badge) => {
        badge.style.visibility = 'visible'
    })

    /*eslint-disable */
    grecaptcha.ready(function() {
      grecaptcha.execute('6Ldlh8MZAAAAAGTlta6TUZTc6k_aUEJDhmnRHYNq', { action: 'submit' }).then(function(captcha) {
        form_data.append(`captcha`, captcha);

        // Hide the recaptcha badge as we are done.
        let badges = document.querySelectorAll('.grecaptcha-badge')
        badges.forEach((badge) => {
            badge.style.visibility = 'hidden'
        })

        axios.post(
          `${API_PREFIX}/wp-json/calendar/v1/update/starred?token=${BEARER_TOKEN}`,
          form_data,
          {
            headers: {
              'Authorization': `Bearer ${BEARER_TOKEN}`,
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          }
        ).then(() => {
          that.getData()
        })
      })
    })
    /*eslint-enable */
  }
  filter(type, value) {
    console.log('filter', type, value)
    let events = this.state.events
    let filters = this.state.filters
    let newPreppedEvents = []
    let currentDate = null

    // Add to list of applied filters
    if (value) {
      filters[type] = value
    } else {
      // Remove filter
      filters[type] = null
    }

    console.log('filtering by', filters)

    events.forEach((event, index) => {
      console.log('event to filter check', event)

      let shouldExclude = null

      if (filters['bookmarked']) {

        console.log('filtering by bookmarked', filters['bookmarked'])

        let found = false

        if (typeof this.state.starredEvents[event.ID] !== 'undefined' && this.state.starredEvents[event.ID]) {
          console.log('event to filter check: FOUND', event)
          found = true
        } else {
          console.log('event to filter check: NOT FOUND', event)
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }

      if (filters['category']) {

        console.log('filtering by categories', filters['category'])

        let found = false

        if (event.categories) {
          event.categories.forEach((category) => {
            if (category === filters['category']) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (filters['topic']) {

        console.log('filtering by topics', filters['topic'])

        let found = false

        if (event.topics) {
          event.topics.forEach((topic) => {
            if (topic === filters['topic']) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (filters['market']) {

        console.log('filtering by markets', filters['market'])

        let found = false

        if (event.markets) {
          event.markets.forEach((market) => {
            if (market === filters['market']) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (filters['channel']) {

        console.log('filtering by channels', filters['channel'])

        let found = false

        if (event.channels) {
          event.channels.forEach((channel) => {
            if (channel === filters['channel']) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }
      if (filters['representative']) {

        console.log('filtering by representatives', filters['representative'])

        let found = false

        if (event.representatives) {
          event.representatives.forEach((rep) => {
            if (rep.ID === parseFloat(filters['representative'])) {
              found = true
            }
          })
        }

        if (found) {
          shouldExclude = shouldExclude ? true : false
        } else {
          shouldExclude = true
        }
      }

      event.isVisible = shouldExclude ? false : true
    
      if (currentDate !== moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY')) {
        newPreppedEvents.push(<EntryDate timestamp={ moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('X') } date={ moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY') } />)
        currentDate = moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format('ddd, DD. MMM YYYY')
      }
        
      newPreppedEvents.push(<Entry isVisible={ event.isVisible } starredEvents={ this.state.starredEvents } setStarredEvents={ (events) => this.setStarredEvents(events) } id={ event.ID } isBookmarked={ typeof this.state.starredEvents[event.ID] !== 'undefined' && this.state.starredEvents[event.ID] ? true : false } date={ moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().format('ddd, DD. MMM YYYY') } meta={ `${moment(`${event.start.year}-${event.start.month}-${event.start.day}T${event.startHour}:${event.startMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format(timeFormat)} - ${moment(`${event.end.year}-${event.end.month}-${event.end.day}T${event.endHour}:${event.endMinute}:00.000Z`).utc().add(timezoneOffsetFromUTC, 'minutes').format(timeFormat)}` } category={ event.categories && event.categories.length > 0 ? event.categories[0] : null } title={ event.title } copy={ event.copy } link={ event.link } speaker={ event.representatives && event.representatives.length > 0 ? `${event.representatives[0].first_name} ${event.representatives[0].last_name}` : null } channel={ event.channels && event.channels.length > 0 ? event.channels[0] : null } />)

      event[index] = event
    })

    console.log('new events', events)
    
    this.setState({
      events,
      preppedEvents: newPreppedEvents
    })
  }
  render() {
    return (
      <div className={`agenda-container ${this.state.isLoading ? `loading` : ``}`}>
        <Filter role={ this.state.role } addSession={(e) => this.setState({ showForm: true })} filter={(type, value) => this.filter(type, value)} selectCategories={ this.state.selectCategories } selectTopics={ this.state.selectTopics } selectMarkets={ this.state.selectMarkets } selectChannels={ this.state.selectChannels } selectRepresentatives={ this.state.selectRepresentatives } />
        { this.state.showForm ? <Form getData={() => this.getData()} close={(e) => this.setState({ showForm: false })} selectParticipants={ this.state.selectParticipants } selectHours={ this.state.selectHours } selectMinutes={ this.state.selectMinutes } selectDays={ this.state.selectDays } selectCategories={ this.state.selectCategories } selectTopics={ this.state.selectTopics } selectMarkets={ this.state.selectMarkets } selectChannels={ this.state.selectChannels } selectRepresentatives={ this.state.selectRepresentatives } /> : null }
        <EntryDate timestamp={ moment(this.state.currentDate).format('X') } date={ this.state.currentDate } />
        <Entries setStarredEvents={ (events) => this.setStarredEvents(events) } starredEvents={ this.state.starredEvents } preppedEvents={ this.state.preppedEvents } events={ this.state.events } setDate={(date) => { this.setState({ currentDate: date }) }} />
      </div>
    );
  }
}

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      isSuccess: false,
      hasError: false,
      errorMessage: null,
      erroneousFields: [],
      create: {
        date: '',
        dateStartHour: '',
        dateStartMinute: '',
        dateEndHour: '',
        dateEndMinute: '',
        topic: '',
        category: '',
        participants: [],
        title: '',
        description: ''
      },
    }
  }
  componentDidMount() {
    // Show the recaptcha badge for transparency.
    let badges = document.querySelectorAll('.grecaptcha-badge')
    badges.forEach((badge) => {
        badge.style.visibility = 'visible'
    })
  }
  create(ev) {
    ev.preventDefault()

    // Reset any existing error flags
    this.setState({
      isLoading: true,
      hasError: false,
      errorMessage: ''
    })

    let hasError = false
    let errorMessage = ''
    let erroneousFields = []

    let date            = this.state.create.date
    let dateStartHour   = this.state.create.dateStartHour
    let dateStartMinute = this.state.create.dateStartMinute
    let dateEndHour     = this.state.create.dateEndHour
    let dateEndMinute   = this.state.create.dateEndMinute
    let topic           = this.state.create.topic
    let category        = this.state.create.category
    let participants    = this.state.create.participants
    let title           = this.state.create.title
    let description     = this.state.create.description

    let dateStart = `${dateStartHour}:${dateStartMinute}`
    let dateEnd   = `${dateEndHour}:${dateEndMinute}`

    // Check for empty fields.
    if (!date || !dateStartHour || !dateStartMinute || !dateEndHour || !dateEndMinute || !topic || !category || !participants || !title || !description) {
      console.log('participants', participants)
      hasError = true
      errorMessage = `There are mandatory fields missing.`

      if (!date) { erroneousFields.push('date') }
      if (!dateStartHour) { erroneousFields.push('dateStartHour') }
      if (!dateStartMinute) { erroneousFields.push('dateStartMinute') }
      if (!dateEndHour) { erroneousFields.push('dateEndHour') }
      if (!dateEndMinute) { erroneousFields.push('dateEndMinute') }
      if (!topic) { erroneousFields.push('topic') }
      if (!category) { erroneousFields.push('category') }
      if (!participants || participants.length === 0) { erroneousFields.push('participants') }
      if (!title) { erroneousFields.push('title') }
      if (!description) { erroneousFields.push('description') }

      // Scroll to top to show error message
      document.querySelector('.form-create > div').scrollTop = 0;

    } else {

      // Check end date is after start date
      if (parseFloat(`${parseFloat(dateStartHour).pad()}${parseFloat(dateStartMinute).pad()}`) >= parseFloat(`${parseFloat(dateEndHour).pad()}${parseFloat(dateEndMinute).pad()}`)) {
        hasError = true
        errorMessage = `Start date must be before end date.`

        // Scroll to top to show error message
        document.querySelector('.form-create > div').scrollTop = 0;
      }
    }

    if (!hasError) {

      var form_data = new FormData();

      let that = this

      form_data.append(`date`, date);
      form_data.append(`dateStart`, dateStart);
      form_data.append(`dateEnd`, dateEnd);
      form_data.append(`topic`, topic);
      form_data.append(`category`, category);
      form_data.append(`participants`, participants);
      form_data.append(`title`, title);
      form_data.append(`description`, description);

      /*eslint-disable */
      grecaptcha.ready(function() {
        grecaptcha.execute('6Ldlh8MZAAAAAGTlta6TUZTc6k_aUEJDhmnRHYNq', { action: 'submit' }).then(function(captcha) {
          form_data.append(`captcha`, captcha);

          // Hide the recaptcha badge as we are done.
          let badges = document.querySelectorAll('.grecaptcha-badge')
          badges.forEach((badge) => {
              badge.style.visibility = 'hidden'
          })

          axios.post(
            `${API_PREFIX}/wp-json/calendar/v1/insert?token=${BEARER_TOKEN}`,
            form_data,
            {
              headers: {
                'Authorization': `Bearer ${BEARER_TOKEN}`,
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            }
          ).then((response) => {
            if (typeof response.data.status !== 'undefined' && response.data.status === 'success') {
              that.props.getData()
              that.setState({
                isLoading: false
              }, () => {
                that.props.close()
              })
            } else {
              alert('An unexpected error occurred. Please contact technical support with the following error code: c04950a.')
            }
          })
        })
      })
      /*eslint-enable */
    } else {
      console.log('hasError', hasError, errorMessage)
      this.setState({
        isLoading: false,
        hasError,
        errorMessage,
        erroneousFields
      })

      // Scroll to top to show error message
      document.querySelector('.form-create > div').scrollTop = 0;
    }
  }
  setCreateInput(field, value) {
    let fields = this.state.create

    if (field === 'participants') {
      let newValue = []
      if (value) {
        value.forEach((option) => {
          newValue.push(option.value)
        })
      }
      value = newValue
    }

    fields[field] = value

    console.log('setCreateInput', fields, field, value)

    this.setState({
      create: fields
    })
  }
  handleSelectChangeDate = selectedOptionDate => {
    this.setCreateInput('date', selectedOptionDate.value)
    this.setState(
      { selectedOptionDate },
      () => console.log(`Option selected:`, this.state.selectedOptionDate)
    );
  }
  handleSelectChangeTopic = selectedOptionTopic => {
    this.setCreateInput('topic', selectedOptionTopic.value)
    this.setState(
      { selectedOptionTopic },
      () => console.log(`Option selected:`, this.state.selectedOptionTopic)
    );
  }
  handleSelectChangeMarket = selectedOptionMarket => {
    this.setCreateInput('market', selectedOptionMarket.value)
    this.setState(
      { selectedOptionMarket },
      () => console.log(`Option selected:`, this.state.selectedOptionMarket)
    );
  }
  handleSelectChangeCategory = selectedOptionCategory => {
    this.setCreateInput('category', selectedOptionCategory.value)
    this.setState(
      { selectedOptionCategory },
      () => console.log(`Option selected:`, this.state.selectedOptionCategory)
    );
  }
  handleSelectChangeStartHour = selectedOptionStartHour => {
    this.setCreateInput('dateStartHour', selectedOptionStartHour.value)
    this.setState(
      { selectedOptionStartHour },
      () => console.log(`Option selected:`, this.state.selectedOptionStartHour)
    );
  }
  handleSelectChangeStartMinute = selectedOptionStartMinute => {
    this.setCreateInput('dateStartMinute', selectedOptionStartMinute.value)
    this.setState(
      { selectedOptionStartMinute },
      () => console.log(`Option selected:`, this.state.selectedOptionStartMinute)
    );
  }
  handleSelectChangeEndHour = selectedOptionEndHour => {
    this.setCreateInput('dateEndHour', selectedOptionEndHour.value)
    this.setState(
      { selectedOptionEndHour },
      () => console.log(`Option selected:`, this.state.selectedOptionEndHour)
    );
  }
  handleSelectChangeEndMinute = selectedOptionEndMinute => {
    this.setCreateInput('dateEndMinute', selectedOptionEndMinute.value)
    this.setState(
      { selectedOptionEndMinute },
      () => console.log(`Option selected:`, this.state.selectedOptionEndMinute)
    );
  }
  handleSelectChangeParticipants = selectedOptionParticipants => {
    this.setCreateInput('participants', selectedOptionParticipants)
    this.setState(
      { selectedOptionParticipants },
      () => console.log(`Option selected:`, this.state.selectedOptionParticipants)
    );
  }
  render() {
    return <form className={`form-create ${this.state.isLoading ? `loading` : ``} ${this.state.isSuccess ? `success` : ``}`} onSubmit={(ev) => this.create(ev)} onClick={(ev) => { ev.stopPropagation() }}>
      <button className={`close`} onClick={(ev) => { ev.preventDefault(); this.props.close() }}>Close</button>
      <div data-simplebar style={{ overflow: 'scroll' }}>
        <p className={`error ${this.state.errorMessage ? `show` : `hide` }`}>{ this.state.errorMessage }</p>
        <label className={`${this.state.erroneousFields.includes('title') ? `error-field` : ``}`}>
          <strong>Session Name</strong>
          <input type="text" name="text" defaultValue="" onChange={(ev) => this.setCreateInput('title', ev.currentTarget.value)} />
        </label>
        <label className={`${this.state.erroneousFields.includes('category') ? `error-field` : ``}`}>
          <strong>Region</strong>
          <Select
            onChange={ this.handleSelectChangeCategory }
            options={ this.props.selectCategories }
          />
        </label>
        <label className={`${this.state.erroneousFields.includes('topic') ? `error-field` : ``}`}>
          <strong>Category</strong>
          <Select
            onChange={ this.handleSelectChangeTopic }
            options={ this.props.selectTopics }
          />
        </label>
        <label className={`${this.state.erroneousFields.includes('market') ? `error-field` : ``}`}>
          <strong>Market</strong>
          <Select
            onChange={ this.handleSelectChangeMarket }
            options={ this.props.selectMarkets }
          />
        </label>
        <label className={`${this.state.erroneousFields.includes('date') ? `error-field` : ``}`}>
          <strong>Date</strong>
          <Select
            onChange={ this.handleSelectChangeDate }
            options={ this.props.selectDays }
          />
        </label>
        <div className={`double`}>
          <div className={`${this.state.erroneousFields.includes('dateStartHour') || this.state.erroneousFields.includes('dateStartMinute') ? `error-field` : ``}`}>
            <strong>Start</strong>
            <span className={`time`}>
              <label>
                <Select
                  value={ this.state.selectedOptionStartHour }
                  onChange={ this.handleSelectChangeStartHour }
                  options={ this.props.selectHours }
                />
              </label>
              :
              <label>
                <Select
                  value={ this.state.selectedOptionStartMinute }
                  onChange={ this.handleSelectChangeStartMinute }
                  options={ this.props.selectMinutes }
                />
              </label>
            </span>
          </div>
          <div className={`${this.state.erroneousFields.includes('dateEndHour') || this.state.erroneousFields.includes('dateEndMinute') ? `error-field` : ``}`}>
            <strong>End</strong>
            <span className={`time`}>
              <label>
                <Select
                  value={ this.state.selectedOptionEndHour }
                  onChange={ this.handleSelectChangeEndHour }
                  options={ this.props.selectHours }
                />
              </label>
              :
              <label>
                <Select
                  value={ this.state.selectedOptionEndMinute }
                  onChange={ this.handleSelectChangeEndMinute }
                  options={ this.props.selectMinutes }
                />
              </label>
            </span>
          </div>
        </div>
        <label className={`${this.state.erroneousFields.includes('participants') ? `error-field` : ``}`}>
          <strong>Participants</strong>
          <Select
            value={ this.state.selectedOptionParticipants }
            onChange={ this.handleSelectChangeParticipants }
            options={ this.props.selectParticipants }
            isMulti={ true }
          />
        </label>
        <label className={`${this.state.erroneousFields.includes('description') ? `error-field` : ``}`}>
          <strong>Description</strong>
          <textarea rows={4} type="text" name="text" defaultValue="" onChange={(ev) => this.setCreateInput('description', ev.currentTarget.value)} />
        </label>
        <label className={`button-label`}>
          <button className={`button active`} type="submit">Create Session</button>
        </label>
      </div>
    </form>
  }
}

class Filter extends React.Component {
  render () {
    return <div className={`filter`}>
      <label className={`special`}>
        <strong>{ LANGUAGE.SHOW_ONLY_MY_EVENTS }</strong>
        <Toggle
          icons={ false }
          aria-label='Show only bookmarked events'
          onChange={ (chosen) => { this.props.filter('bookmarked', chosen.currentTarget.checked) } } />
      </label>
      {
        this.props.selectCategories && this.props.selectCategories.length > 1 ?
          <label>
            <strong>Region</strong>
            <Select
              onChange={ (chosen) => this.props.filter('category', chosen.value) }
              options={ this.props.selectCategories }
            />
          </label>
        : null
      }
      {
        this.props.selectTopics && this.props.selectTopics.length > 1 ?
          <label>
            <strong>Category</strong>
            <Select
              onChange={ (chosen) => this.props.filter('topic', chosen.value) }
              options={ this.props.selectTopics }
            />
          </label>
        : null
      }
      {
        this.props.selectMarkets && this.props.selectMarkets.length > 1 ?
          <label>
            <strong>Market</strong>
            <Select
              onChange={ (chosen) => this.props.filter('market', chosen.value) }
              options={ this.props.selectMarkets }
            />
          </label>
        : null 
      }
      {
        this.props.selectChannels && this.props.selectChannels.length > 1 ?
          <label>
            <strong>Channel</strong>
            <Select
              onChange={ (chosen) => this.props.filter('channel', chosen.value) }
              options={ this.props.selectChannels }
            />
          </label>
        : null
      }
      {
        this.props.selectRepresentatives && this.props.selectRepresentatives.length > 1 ?
          <label>
            <strong>Speaker</strong>
            <Select
              onChange={ (chosen) => this.props.filter('representative', chosen.value) }
              options={ this.props.selectRepresentatives }
            />
          </label>
        : null
      }
      <label className={`simple ${this.props.role} extended`} data-role={ this.props.role }>
        { this.props.role === 'representative' ? <button onClick={(ev) => { ev.stopPropagation(); this.props.addSession() }} className={`button secondary`}>Add Session</button> : null }
      </label>
    </div>
  }
}

class Entries extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      events: []
    }
  }
  onScroll(e) {
    let currentTop = e.currentTarget.scrollTop
    let entries = document.querySelectorAll('.entry')
    let found = false

    // check which entry date is closest
    entries.forEach((element) => {
      if (element.offsetTop - (document.querySelector('.entry-date').clientHeight / 2) > currentTop && !found) {
        found = element
      }
    })
    
    if (found) {
      this.props.setDate(found.getAttribute('data-date'))
    }
  }
  render () {
    return <div className={`entries`} onScroll={(e) => this.onScroll(e)}>
      { this.props.preppedEvents }
    </div>
  }
}

class EntryDate extends React.Component {
  render () {
    return <div className={`entry-date`} data-date={ this.props.date }>
      <h3 data-date-inline={ this.props.timestamp }>{ this.props.date }</h3>
    </div>
  }
}

class Entry extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: false,
      isLoading: false
    }
  }
  toggleStar(ev, ID) {
    ev.preventDefault()
    ev.stopPropagation()

    this.setState({
      isLoading: false
    }, () => {
      this.setState({
        isLoading: true
      })
    })

    let starredEvents = this.props.starredEvents
    console.log('toggleStar', this.props)

    if (this.props.isBookmarked) {
      // Unstar
      starredEvents[ID] = false
    } else {
      // Star
      starredEvents[ID] = true
    }

    this.props.setStarredEvents(starredEvents)
  }
  render () {
    return <a href={ DISABLE_LINKS ? "#" : this.props.link } className={`entry ${ this.props.isVisible ? `visible` : `hidden` } ${ this.state.isLoading ? `loading` : `` }`} data-date={ this.props.date }>
      <div>
        <em>{ this.props.meta }</em>
        <strong className={`category`}>{ this.props.category }</strong>
        <strong className={`title`}>{ !DISABLE_LINKS ? <span onClick={(ev) => this.toggleStar(ev, this.props.id)} className={`star ${this.props.isBookmarked ? `active` : ``}`}><span>{ TOOLTIP_TEXT }</span></span> : null} { this.props.title }</strong>
        <p className={`meta`}>{ this.props.copy }</p>
        <ul>
          { this.props.speaker ? <li>{ this.props.speaker }</li> : null }
          { this.props.channel ? <li>{ this.props.channel }</li> : null }
        </ul>
      </div>
    </a>
  }
}

export default App;

/*eslint-disable */
Number.prototype.pad = function(size) {
  var s = String(this);
  while (s.length < (size || 2)) {s = "0" + s;}
  return s;
}
/*eslint-enable */